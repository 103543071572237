<template>
  <div class="onStart">
    <SignAdmin />
  </div>
</template>
<script>
import SignAdmin from "../components/SignAdmin.vue";
export default {
  name: "OnStart",
  components: {
    SignAdmin,
  },
  computed: {
    locale() {
      return this.$store.getters["general/locale"];
    },
  },
  watch: {
    locale(locale, oldLocale) {
      console.log("locale: " + locale, "old: " + oldLocale);
    },
  },
};
</script>
<style lang="scss" scoped></style>
