import Validator from "@/helpers/validator";

export default {
  data() {
    return {
      form: {},
      errors: {},
      textErrors: {},
    };
  },
  created() {
    this.v$ = new Validator();
  },
  computed: {
    formValid() {
      return !Object.keys(this.errors).length;
    },
  },
  mounted() {
    this.errors = {};
    for (const key in this.form) {
      this.form[key] = null;
    }
  },
  methods: {
    setForm(form) {
      const rules = {};
      for (const key in form) {
        this.form[key] = null;
        if (form[key].length) {
          rules[key] = form[key];
        }
      }
      this.setRules(rules);
    },
    setRules(rules) {
      this.v$.setForm(rules);
    },
    validate() {
      const valid = this.v$.validate(this.form);
      if (!valid) return (this.errors = this.v$.$errors);
      this.errors = {};
      return this.formValid;
    },
    validateProperty(property) {
      const valid = this.v$.validateProperty(property, this.form[property]);
      if (!valid) {
        this.errors[property] = this.v$.$properties[property].errors;
      } else {
        this.errors[property] = null;
      }
    },
    setError(property, message) {
      const error = {
        property: property,
        rule: message,
        parameter: message,
        parameter_name: message,
        message: message,
        clean_message: message,
      };
      this.errors[property] = [error];
    },
    clearForm() {
      for (const prop in this.form) {
        this.form[prop] = null;
      }
    },
  },
};
