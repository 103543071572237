// Vue Ionic Validator
"use strict";

import uuid from "./uuid";

var INSTANCES = {};

class Validator {
  constructor() {
    this.uuid = uuid();
    // console.log("this.uuid", this.uuid);
    this.$errors = {};
    this.$properties = {};
    this.$valid = true;
    INSTANCES[this.uuid] = this;
  }

  setForm(form) {
    // console.log("INSTANCES", INSTANCES);
    for (const key in form) {
      if (Object.hasOwnProperty.call(form, key)) {
        this.$properties[key] = new Property(this.uuid, key, form[key]);
      }
    }
  }

  validate(form) {
    // console.log(this.uuid);
    // console.log("INSTANCES", INSTANCES);
    this.$valid = true;
    this.$errors = {};
    for (const property of Object.values(this.$properties)) {
      const parameter = form[property.name] || null;
      if (property.rules.length) property.validate(parameter);
      if (property.errors.length) this.$errors[property.name] = property.errors;
    }
    if (Object.keys(this.$errors).length) this.$valid = false;
    return this.$valid;
  }

  validateProperty(property, value) {
    // console.log('validateProperty', property, value);
    this.$properties[property].validate(value);
    // console.log('this.$properties[property]', this.$properties[property]);
    return this.$properties[property].valid;
    // console.log('result', result);
  }

  getErrors() {
    return this.$properties.map((p) => p.errors);
  }

  error(property) {
    console.log("Validator - get error", property);
    return this.$errors[property] || null;
  }
}

class Property {
  constructor(validator, name, data) {
    this.$validator = validator;
    this.value = null;
    this.name = name;
    this.rules = [];
    this.valid = true;
    this.errors = [];
    if (data) this.buildRules(data);
  }

  buildRules(data) {
    for (const rule of data) {
      const splitedRule = rule.split("|");
      let parameter = splitedRule[1];
      if (parameter && !isNaN(parameter)) parameter = parseFloat(parameter);
      let message = splitedRule[0].match(/\[(.*?)\]/);
      message = message ? message[1] : null;
      const ruleName = message
        ? splitedRule[0].replace(`[${message}]`, "")
        : splitedRule[0];
      if (!RULES[ruleName]) {
        throw new Error("Could not find the validation function - " + ruleName);
      }
      let ruleObj = {
        type: ruleName,
        parameter: parameter,
        parameter_name: parameter ? String(parameter) : null,
        message: message,
        func: RULES[ruleName],
      };
      // this.fixParameter(ruleName, parameter);
      this.fixParameter(ruleObj);
      //   console.log("ruleObj", ruleObj);
      this.rules.push(ruleObj);
    }
  }

  fixParameter(ruleObj) {
    switch (ruleObj.type) {
      case "confirm":
        ruleObj.parameter_name = this.name.replace("_confirmation", "");
        ruleObj.parameter =
          INSTANCES[this.$validator].$properties[ruleObj.parameter_name].value;
        break;
      default:
        break;
    }
  }

  validate(parameter) {
    this.value = parameter;
    this.errors = [];
    this.valid = true;
    for (const rule of this.rules) {
      this.fixParameter(rule);
      const result = rule.func(parameter, rule.parameter, rule.parameter_name);
      if (typeof result === "string") {
        const clean_message = rule.message || result;
        const name = titleCase(this.name);
        const message = clean_message.replace("{property}", name);
        this.errors.push({
          property: this.name,
          rule: rule.type,
          parameter: rule.parameter,
          parameter_name: titleCase(rule.parameter_name),
          message: message,
          clean_message: clean_message,
        });
      }
    }
    if (this.errors.length) this.valid = false;
  }
}

const RULES = {
  required: (v) => !!v || "{property} is required",
  email: (v) => validateEmail(v) || "{property} isn't valid",
  phone: (v) => validatePhone(v) || "{property} isn't a valid phone number",
  length: (v, length) =>
    (!!v && v.length == length) ||
    `{property} must be exactly ${length} characters`,
  minLength: (v, limit) =>
    (!!v && v.length >= limit) ||
    `{property} must be longer then ${limit} characters`,
  maxLength: (v, limit) =>
    (!!v && v.length <= limit) ||
    `{property} must be shorter then ${limit} characters`,
  confirm: (v, sameAsValue, sameAsName) =>
    (!!v && v === sameAsValue) ||
    `{property} must be the same as ${titleCase(sameAsName)}`,
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const validatePhone = (phone) => {
  return String(phone).match(/^05\d([-]{0,1})\d{7}$/);
};

const titleCase = (string) => {
  if (!string) return string;
  string = string.split("_").join(" ");
  return string.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
};

export default Validator;
