<template>
  <div>
    <h1>Sign As Admin To Start</h1>
    <div class="container">
      <form @submit.prevent="submit">
        <div class="input-container">
          <label>Phone: </label>
          <input type="text" v-model="form.phone" autofocus />
        </div>
        <small v-if="errors.phone">{{ errors.phone[0].message }}</small>
        <div class="input-container">
          <label>Password: </label>
          <input type="password" v-model="form.password" />
        </div>
        <small v-if="errors.password">{{ errors.password[0].message }}</small>
        <button type="submit">Sign In</button>
      </form>
    </div>
  </div>
</template>
<script>
import FormMixin from "../mixins/FormMixin";
export default {
  name: "signAdmin",
  mixins: [FormMixin],
  created() {
    this.setForm({
      phone: ["required"],
      password: ["required"],
    });
  },
  methods: {
    async submit() {
      await this.validate();
      console.log(this.formValid);
      console.log(this.errors);
      this.$axios
        .post("/sign-in", this.form, { withCredentials: true })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          this.$axios.defaults.headers.common = {
            Authorization: "Bearer " + res.data.token,
          };
          this.$store.dispatch("general/setAlert", {
            type: "success",
            msg: "Admin Loged In!",
          });
          this.$store.dispatch("user/setUser", res.data);
          let redirect = "/";
          if (this.$route.query.redirect) {
            redirect = this.$route.query.redirect;
          }
          this.$router.push(redirect);
        })
        .catch(() => {
          this.$store.dispatch("general/setAlert", {
            type: "failed",
            msg: "Admin Not Found!",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.container {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .input-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 6px;
      margin-bottom: 6px;
      label {
        margin-inline-end: 10px;
      }
    }
  }
}
</style>
